@tailwind base;
@tailwind components;
@tailwind utilities;

.clip-bottom {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

.zoom {
  width: 600px;
  height: 600px;
  top: calc(50% - 300px);
}

.zoom.column-1 {
  left: calc(25% + 1.25rem);
}

.zoom.column-2 {
  left: calc(50% + 0.5rem);
}

.zoom.column-3 {
  right: calc(50% + 0.5rem);
}

.zoom.column-4 {
  right: calc(25% + 1.25rem);
}

@media (min-width: 1400px) {
  .zoom.column-1 {
    left: calc(50% - 350px + 1rem)
  }

  .zoom.column-4 {
    right: calc(50% - 350px + 1rem);
  }
}


.zoom > div {
  position: absolute;
  height: 600px;
  width: 600px;
}

.zoom.active > div {
  border-width: 1px;
}

.zoom.active > div > img {
  background: white !important;
}
