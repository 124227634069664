.step-item {
  @apply relative flex w-36 flex-col items-center justify-center;
}
.step-item:not(:first-child):before {
  @apply absolute right-[3.5em] top-[1em] h-[2px] w-full -translate-y-2/4 bg-gray-200 content-[''];
}
.step {
  @apply relative flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-gray-500 outline outline-4 outline-slate-50;
}
.active .step {
  @apply bg-blue-600 text-white;
}
.complete .step {
  @apply bg-green-600 text-white;
}
.complete p {
  @apply text-white;
}
.complete:not(:first-child):before,
.active:not(:first-child):before {
  @apply bg-green-600;
}
