.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
}

.timeline-container::after {
    background-color: #cbd5e1;
    content: '';
    position: absolute;
    left: calc(27% - 3px);
    width: 2px;
    top: 45px;
    height: 100%;
}

.timeline-item {
    display: flex;
    align-self: flex-end;
    justify-content: flex-start;
    padding-right: 0;
    position: relative;
    margin: 10px 0;
}

.timeline-item-content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 5px;
    position: relative;
    width: 400px;
    max-width: 70%;
    text-align: left;
    align-items: flex-start;
}

.timeline-item-content time {
    color: #777;
    font-size: 12px;
    font-weight: bold;
}

.timeline-item-content p {
    font-size: 16px;
    line-height: 20px;
    margin: 5px 0;
    max-width: 250px;
}

.timeline-item-content a {
    font-size: 14px;
    font-weight: bold;
}

.timeline-item-content a::after {
    content: ' ►';
    font-size: 12px;
}

.circle-box .circle-common {
    background-color: #fff;
    border: 2px solid #cbd5e1;
    border-radius: 50%;
    position: absolute;
    right: auto;
    width: 40px;
    height: 40px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-box .circle-last {
    background-color: #14B8A6;
    border: 2px solid #cbd5e1;
    border-radius: 50%;
    position: absolute;
    right: auto;
    width: 40px;
    height: 40px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
